import { render, staticRenderFns } from "./ListFolder.vue?vue&type=template&id=601a820b&scoped=true&"
import script from "./ListFolder.vue?vue&type=script&lang=js&"
export * from "./ListFolder.vue?vue&type=script&lang=js&"
import style0 from "./ListFolder.vue?vue&type=style&index=0&id=601a820b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "601a820b",
  null
  
)

/* custom blocks */
import block0 from "./ListFolder.vue?vue&type=custom&index=0&blockType=i18nf"
if (typeof block0 === 'function') block0(component)

export default component.exports